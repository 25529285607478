import React from "react"
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {emailRefLogin} from "../auth/Login";

/*export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async () => {
    await console.log('emailRefLogin=', emailRefLogin)
  }
)*/

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      loggedIn: false,
      authUID: '',
      authIdToken: '',
      authEmail: '',
      authExpiresIn: '',
      showAlert: false,
    }
  },
  reducers: {
    loggingIn: (state, action) => {
      const user = action.payload
      state.user = user
    },
    showAlert: (state, action) => {
      state.user.showAlert = action.payload
    },
  },
  /*  extraReducers: (builder) => {
      builders
        .addCase(loginAsync.fulfilled, (state, action) => {
          console.log('OK emailRefLogin=', emailRefLogin)
        })
    }*/
})

export const {loggingIn, showAlert} = authSlice.actions

export default authSlice.reducer