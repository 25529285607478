import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addTodo, addTodoAsync} from '../../app/todoSlice'
import {Alert} from "react-bootstrap";
import {showAlert} from "../../app/authSlice";
import classes from "./AddTodoForm.module.css"


const AddTodoForm = () => {
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const testState = useSelector((state) => state)
  const alertState = useSelector((state) => state.auth.user.showAlert)
  const authState = useSelector((state) => state.auth)
  const authUID = authState.user.authUID
  const authIdToken = authState.user.authIdToken

  const onSubmit = (event) => {
    event.preventDefault()

    window.localStorage.getItem('simpletodoLoggedIn')
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))

    if (value) {
      /*      dispatch(
              addTodo({
                title: value
              })
            )*/
      dispatch(
        addTodoAsync({
          title: value, authUID, authIdToken
        })
      )
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className='form-inline mt-3 mb-2'>
        <label className='sr-only'>Name</label>
        <input
          // style={{minHeight: '56px', border: 'none', background: '#FAF6EA'}}
          type='text'
          className={['form-control', 'mb-2', 'mr-sm-2', classes.AddTodoForm_input].join(' ')}
          placeholder='Напишите задачу...'
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <button
          type='submit'
          // className='btn btn-primary mb-2'
          className={classes.AddTodoForm_button}
        >
          Добавить в список
        </button>
        {alertState
          ? <Alert
            variant="danger"
            className={classes.AddTodoForm_alert}
          >
            Создание и редактирование списков доступно после регистрации
        </Alert>
          : null}
      </form>
{/*
      <button
        onClick={() => window.localStorage.clear()}>
        Clear localStorage
      </button>

      <button
        onClick={() => console.log(localStorage)}>
        Test localStorage
      </button>

      <button
        onClick={() => {
          console.log('testState=', testState)
          console.log('test_todosArr=', Object.values(testState)[0])
          console.log('test_index=', Object.values(testState)[0].findIndex((todo) => todo.id === 1669950863804))
          // console.log('test_index=', Object.values(testState.todos)[0].findIndex((todo) => todo.id === action.payload.todo.id))
        }}>
        Test State
      </button>
      */}
    </>
  );
};

export default AddTodoForm;
