import React, {useEffect} from 'react';
// import './App.css';
import Container from "react-bootstrap/Container"
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import TodoMain from "./components/TodoMain/TodoMain";
import {Navigate} from "react-router";
import UpdateProfile from "./auth/UpdateProfile";
import Signup from "./auth/Signup";
import PrivateRoute from "./auth/PrivateRoute";
import ForgotPassword from "./auth/ForgotPassword";
import Login from "./auth/Login";
import MenuNavBar from "./components/MenuNavBar/MenuNavBar";
import {AuthProvider} from "./auth/AuthContext";
import {useDispatch} from "react-redux";
import {loggingIn} from "./app/authSlice";

function App() {

  let loggedIn
  // let authExpiresIn
  Number(window.localStorage.getItem('simpletodoAuthExpiresIn'))
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
      ? loggedIn = true
      : loggedIn = false
    : loggedIn = false

  let authUID
  window.localStorage.getItem('simpletodoAuthUID')
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
      ? authUID = window.localStorage.getItem('simpletodoAuthUID')
      : authUID = 'WorlrJXI23MDVJYDBLJeFyK53CA2'
    : authUID = 'WorlrJXI23MDVJYDBLJeFyK53CA2'

  let authIdToken
  window.localStorage.getItem('simpletodoAuthIdToken')
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
      ? authIdToken = window.localStorage.getItem('simpletodoAuthIdToken')
      : authIdToken = ''
    : authIdToken = ''

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loggingIn({
      loggedIn: loggedIn,
      authUID: authUID,
      authIdToken: authIdToken,
    }))
  }, [])

  return (
    <>
      <MenuNavBar/>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<TodoMain/>}/>
          {/*<Route path="/auth" element={<Auth/>}/>*/}
          {/*<PrivateRoute path="/update-profile" element={<UpdateProfile/>}/>*/}
          <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<TodoMain/>}/>
          </Route>

          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/forgot-password" element={<ForgotPassword/>}/>
          <Route path="/" exact element={<TodoMain/>}/>
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
      </AuthProvider>
    </>
  )
}

export default App;
