// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJBURwgDOzVusXsVx1ArLaUJ7lJgDwz3A",
  authDomain: "todo-f1486.firebaseapp.com",
  databaseURL: "https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "todo-f1486",
  storageBucket: "todo-f1486.appspot.com",
  messagingSenderId: "779664100727",
  appId: "1:779664100727:web:bc83374363de16b1ef6572"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth()

export default app