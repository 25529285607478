import AddTodoForm from "../AddTodoForm/AddTodoForm";
import TodoList from "../TodoList/TodoList";
import TotalCompleteItems from "../TotalCompleteItems/TotalCompleteItems";
import React from "react";
import classes from './TodoMain.module.css'

const TodoMain = () => {
  return (
    <div className='container bg-white p-4 mt-5'>
      <h1 className={classes.TodoMain_h1}>Список дел</h1>
      <AddTodoForm/>
      <TodoList/>
      <TotalCompleteItems/>
    </div>
  )
}

export default TodoMain