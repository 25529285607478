import { configureStore } from '@reduxjs/toolkit';
import todoReducer from './todoSlice';
import authReducer from "./authSlice";
// import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
  reducer: {
    todos: todoReducer,
    auth: authReducer,
    // counter: counterReducer,
  },
});
