import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toggleComplete, deleteTodo, toggleCompleteAsync, deleteTodoAsync} from "../../app/todoSlice";
import {showAlert} from "../../app/authSlice";
import classes from "./TodoItem.module.css"

const TodoItem = ({ id, title, completed, dataBaseId }) => {
	const dispatch = useDispatch()
	const authState = useSelector((state) => state.auth)
	const authUID = authState.user.authUID
	const authIdToken = authState.user.authIdToken

	const handleCheckboxClick = () => {

		window.localStorage.getItem('simpletodoLoggedIn')
			? dispatch(showAlert(false))
			: dispatch(showAlert( true))

		// dispatch(toggleComplete({ id, completed: !completed}))
		dispatch(toggleCompleteAsync({dataBaseId, id, completed: !completed, authUID, authIdToken}))
	}

	const handleDeleteClick = () => {
		// dispatch(deleteTodo({id}))
		dispatch(deleteTodoAsync({dataBaseId, id, authUID, authIdToken}))

		window.localStorage.getItem('simpletodoLoggedIn')
			? dispatch(showAlert(false))
			: dispatch(showAlert(true))
	}

	return (
		<li
			className={`list-group-item ${completed && 'list-group-item-success'}`}
		>
			<div
				className='d-flex justify-content-between'
			>
				<span
					className={['d-flex', 'align-items-center', classes.TodoItem_span].join(' ')}
				>
					<input
						type='checkbox'
						// className='mr-3'
						className={classes.TodoItem_input_checkbox}
						checked={completed}
						onClick={handleCheckboxClick}
					>
					</input>
					{title}
				</span>
				<button
					className={classes.AddTodoForm_delete_button}
					onClick={handleDeleteClick}
				>
					Удалить
				</button>
			</div>
		</li>
	);
};

export default TodoItem;
