import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Logout from "../../auth/Logout";
import {useDispatch, useSelector} from "react-redux";
import {loggingIn} from "../../app/authSlice";
import {useAuth} from "../../auth/AuthContext";
import classes from "./MenuNavBar.module.css";

function MenuNavBar() {
/*  const loggedIn = localStorage.getItem('simpletodoLoggedIn')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loggingIn({
      loggedIn: loggedIn,
    }))
  }, [])*/
  const loggedInState = useSelector((state) => state.auth.user.loggedIn)

  return (
    <Navbar
      // bg="light"
      expand="lg"
      className={classes.MenuNavBar_navbar}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/" className={classes.MenuNavBar_link_logo}><img src='/img/logo.svg'/></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        {/*todo Чтобы не было перезагрузки страницы при переключении по меню - нужно Nav.Link href заменить на Link to="/....."  но пропадут стили - надо будет добавить*/}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/" className={classes.MenuNavBar_link}>Список дел</Link>
            {
              loggedInState
                ? null
                : <>
                  <Link to="/login" className={classes.MenuNavBar_link}>Вход</Link>
                  <Link to="/signup" className={classes.MenuNavBar_link}>Регистрация</Link>
                </>
            }
            {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>*/}
          </Nav>
          {
            loggedInState
            ? <Logout/>
            : null}
          {/*<Navbar.Text className="justify-content-end">*/}
          {/*  Вы вошли как: <a href="#login">Mark Otto</a>*/}
          {/*  <Navbar.Text className="justify-content-end">*/}
          {/*    <a href="#login">Выход</a>*/}
          {/*  <svg style={{width: '18', height: '18', marginLeft: '10', marginBottom: '2'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"/></svg>*/}
          {/*</Navbar.Text>*/}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MenuNavBar