import React, {useContext, useState, useEffect} from "react"
import {auth} from "../firebase/firebase"
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {useDispatch} from "react-redux";
import {loggingIn} from "../app/authSlice"
import {useNavigate} from "react-router-dom";
import {addTodoAsync, getTodoAsync} from "../app/todoSlice";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log('OK user signed up=', user)
        dispatch(loggingIn({
          loggedIn: true,
          authUID: user.uid,
          authEmail: user.email,
          authExpiresIn: user.stsTokenManager.expirationTime,
          authIdToken: user.accessToken,
          // authExpiresIn: Number(user.stsTokenManager.expirationTime.toString().slice(0, 10))
        }))
        setError("")
        navigate("/")
        window.localStorage.setItem('simpletodoLoggedIn', true)
        window.localStorage.setItem('simpletodoAuthUID', user.uid)
        window.localStorage.setItem('simpletodoAuthExpiresIn', user.stsTokenManager.expirationTime)
        window.localStorage.setItem('simpletodoAuthIdToken', user.accessToken)
        // window.localStorage.setItem('simpletodoAuthExpiresIn', Number(user.stsTokenManager.expirationTime.toString().slice(0, 10)))

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errorCode=', errorCode)
        console.log('errorMessage=', errorMessage)
        setError(errorCode)
        dispatch(loggingIn({
          loggedIn: false,
          authErrorCode: errorCode,
          authErrorMessage: errorMessage,
        }))
      })
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log('OK user signed in=', user)
        dispatch(loggingIn({
          loggedIn: true,
          authUID: user.uid,
          authEmail: user.email,
          authExpiresIn: user.stsTokenManager.expirationTime,
          authIdToken: user.accessToken,

          // authExpiresIn: Date.now() + Number(user.stsTokenManager.expirationTime) * 1000
          // authExpiresIn: Number(user.stsTokenManager.expirationTime.toString().slice(0, 10))
        }))
        // dispatch(getTodoAsync())
        setError("")
        navigate("/")
        window.localStorage.setItem('simpletodoLoggedIn', true)
        window.localStorage.setItem('simpletodoAuthUID', user.uid)
        window.localStorage.setItem('simpletodoAuthExpiresIn', user.stsTokenManager.expirationTime)
        window.localStorage.setItem('simpletodoAuthIdToken', user.accessToken)
        // window.localStorage.setItem('simpletodoAuthExpiresIn', Number(user.stsTokenManager.expirationTime.toString().slice(0, 10)))
      })
      // .then(() => dispatch(getTodoAsync()))
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errorCode=', errorCode)
        console.log('errorMessage=', errorMessage)
        setError(errorCode)
        dispatch(loggingIn({
          loggedIn: false,
          authErrorCode: errorCode,
          authErrorMessage: errorMessage,
        }))
      })
  }

  function logout() {
    return signOut(auth).then(() => {
      console.log('Sign-out - successful')
      dispatch(loggingIn({
        loggedIn: false,
        authUID: 'WorlrJXI23MDVJYDBLJeFyK53CA2',
        authEmail: '',
        authExpiresIn: '',
        authIdToken: '',
      }))
      window.localStorage.removeItem('simpletodoLoggedIn')
      // window.localStorage.removeItem('simpletodoAuthUID')
      window.localStorage.setItem('simpletodoAuthUID', 'WorlrJXI23MDVJYDBLJeFyK53CA2')
      window.localStorage.removeItem('simpletodoAuthExpiresIn')
      window.localStorage.removeItem('simpletodoAuthIdToken')
    }).catch((error) => {
      console.log('Sign-out - an error happened')
    })
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    error,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
