import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import React from "react";

/*let authUID
window.localStorage.getItem('simpletodoAuthUID')
  ? authUID = window.localStorage.getItem('simpletodoAuthUID')
  : authUID = 'WorlrJXI23MDVJYDBLJeFyK53CA2'

const authIdToken = window.localStorage.getItem('simpletodoAuthIdToken')*/

export const getTodoAsync = createAsyncThunk(
  'todos/getTodoAsync',
  async (payload) => {
    try {
      const response = await axios.get(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${payload.authUID}.json?auth=${payload.authIdToken}`)

      /*await axios.get(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${quizAuthUID}.json?auth=${quizAuthIdToken}`)*/
      console.log('Получено с сервера response= ', response)
      console.log('Получено с сервера response.data= ', response.data)
      let todos
      const dataBaseIds = Object.keys(response.data || {})
      todos = Object.values(response.data || {})
        .map((todo, id) => {
          return (
            {
              ...todo,
              dataBaseId: dataBaseIds[id]
            }
          )
        })
      window.setTimeout(() => console.log('todos=', todos), 150)
      console.log('dataBaseIds=', dataBaseIds)
      return {todos}
    } catch (error) {
      console.log(error)
    }
  }
)

export const toggleCompleteAsync = createAsyncThunk(
  'todos/toggleCompleteAsync',
  async (payload) => {
    try {
      const response = await axios.patch(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${payload.authUID}/${payload.dataBaseId}.json?auth=${payload.authIdToken}`, {
        completed: payload.completed,
        id: payload.id
      })
      console.log('payload= ', payload)
      console.log('Отправлено на сервер= ', response.data)
      const todo = response.data
      return {todo}
    } catch (error) {
      console.log(error)
    }
  }
)

export const addTodoAsync = createAsyncThunk(
  'todos/addTodoAsync',
  async (payload) => {
    try {
      const id = Date.now()
      const response = await axios.post(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${payload.authUID}.json?auth=${payload.authIdToken}`, {
        id: id,
        title: payload.title,
        completed: false,
        dataBaseId: '',
      })

      /*await axios.post(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${quizAuthUID}.json?auth=${quizAuthIdToken}`, this.state.quiz)*/

      console.log('Отправлено на сервер= ', response.data.name)
      console.log('payload= ', payload)
      const todo = {
        id: id,
        title: payload.title,
        completed: false,
        dataBaseId: response.data.name,
      }
      return {todo}
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteTodoAsync = createAsyncThunk(
  'todos/deleteTodoAsync',
  async (payload) => {
    try {
      const response = await axios.delete(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${payload.authUID}/${payload.dataBaseId}.json?auth=${payload.authIdToken}`)
      console.log('Удалена строка= ')
      const deletedId = {
        id: payload.id
      }
      return {deletedId}
    } catch (error) {
      console.log(error)
    }
  }
)

export const todoSlice = createSlice({
  name: 'todos',
  initialState: [
    /*    todos: [
          /!*    { id: 1, dataBaseId: '', title: 'todo1', completed: false },
              { id: 2, dataBaseId: '', title: 'todo2', completed: false },
              { id: 3, dataBaseId: '', title: 'todo3', completed: true },
              { id: 4, dataBaseId: '', title: 'todo4', completed: false },
              { id: 5, dataBaseId: '', title: 'todo5', completed: false },*!/
          ],
    */
  ]
  ,
  reducers: {
    addTodo: (state, action) => {
      const todo = {
        id: new Date(),
        title: action.payload.title,
        completed: false,
        dataBaseId: '',
      }
      state.push(todo)
    },
    toggleComplete: (state, action) => {
      const index = state.findIndex((todo) => todo.id === action.payload.id)
      state[index].completed = action.payload.completed
    },
    deleteTodo: (state, action) => {
      return state.filter((todo) => todo.id !== action.payload.id)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTodoAsync.fulfilled, (state, action) => {
        return action.payload.todos
      })
      .addCase(toggleCompleteAsync.fulfilled, (state, action) => {
        const index = state.findIndex((todo) => todo.id === action.payload.todo.id)
        console.log('index=', index)
        state[index].completed = action.payload.todo.completed;
      })
      .addCase(addTodoAsync.fulfilled, (state, action) => {
        state.push(action.payload.todo)
      })
      .addCase(deleteTodoAsync.fulfilled, (state, action) => {
        return state.filter((todo) => todo.id !== action.payload.deletedId.id)
      })
  }

})

export const {addTodo, toggleComplete, deleteTodo} = todoSlice.actions

export default todoSlice.reducer