import React, {useRef, useState} from "react"
import {Form, Button, Card, Alert} from "react-bootstrap"
import {useAuth} from "./AuthContext"
import {Link, useNavigate} from "react-router-dom"
import classes from "./Auth.module.css";

export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const {signup} = useAuth()
  const {error} = useAuth()
  const [errorSignup, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    // try {
    // setError("")
    setLoading(true)
    await signup(emailRef.current.value, passwordRef.current.value)
      .then(() => setLoading(false))
    // navigate("/")
    /*    } catch (error) {
          setError("Failed to create an account")
          const errorCode = error.code
          const errorMessage = error.message
          console.log('Failed to create an account')
          console.log('errorCode', errorCode)
          console.log('errorMessage', errorMessage)
        }*/

    // setLoading(false)
  }

  return (
    <>
      <div className='container d-flex align-items-center justify-content-center bg-white p-4 mt-5 mw-100'>
        <Card className={classes.Auth_card}>
          <Card.Body className={classes.Auth_card_body}>
            <p
              // className="text-center mb-4"
              className={classes.Auth_title}
            >
              Регистрация
            </p>
            {errorSignup && <Alert variant="danger">{errorSignup}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label className={classes.Auth_lable}>Логин</Form.Label>
                <Form.Control className={classes.Auth_control} type="email" ref={emailRef} placeholder={'e-mail'}
                              required/>
              </Form.Group>
              <Form.Group id="password">
                <Form.Label className={classes.Auth_lable}>Пароль</Form.Label>
                <Form.Control className={classes.Auth_control} type="password" ref={passwordRef}
                              placeholder={'********'} required/>
              </Form.Group>
              <Form.Group className="mt-2" id="password-confirm">
                <Form.Label className={classes.Auth_lable}>Подтвердите пароль</Form.Label>
                <Form.Control className={classes.Auth_control} type="password" ref={passwordConfirmRef}
                              placeholder={'********'} required/>
              </Form.Group>
              <div className={classes.Auth_div_for_button}>
                <button
                  // className={[classes.Auth_button, 'mt-4'].join(' ')}
                  className={classes.Auth_button}
                  disabled={loading}
                  // className="w-100 mt-4"
                        type="submit">
                  Зарегистрироваться
                </button>
              </div>
            </Form>
          </Card.Body>
          <div
            // className="w-100 text-center mb-4"
            className={[classes.Auth_bottom_note, 'w-100', 'text-center', 'mb-4'].join(' ')}
          >
            Уже есть аккаунт? <Link to="/login" className={classes.Auth_bottom_note_link}>Вход</Link>
          </div>
        </Card>
      </div>
    </>
  )
}
