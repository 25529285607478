import React, {useEffect} from 'react';
import TodoItem from '../TodoItem/TodoItem';
import {useDispatch, useSelector} from "react-redux";
import {getTodoAsync} from "../../app/todoSlice";
import classes from "./TodoList.module.css"

const TodoList = () => {
  const dispatch = useDispatch()
  const todos = useSelector((state) => state.todos)
  const authState = useSelector((state) => state.auth)
  const authUID = authState.user.authUID
  const authIdToken = authState.user.authIdToken
  const status = authState.user.loggedIn

  /*  useEffect(() => {
      dispatch(getTodoAsync({authUID, authIdToken}))
    }, [dispatch])*/

  useEffect(() => {
    dispatch(getTodoAsync({authUID, authIdToken}))
  }, [status])

  return (
    <ul
      className={['list-group', classes.TodoList_ul].join(' ')}
    >
      {
        todos
          ?
          todos.map((todo) => (
            <TodoItem
              id={todo.id}
              title={todo.title}
              completed={todo.completed}
              dataBaseId={todo.dataBaseId}
            />
          ))
          : null
      }
    </ul>
  );
};

export default TodoList;
