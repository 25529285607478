import React from 'react';
import {useSelector} from "react-redux";
import classes from "./TotalCompleteItems.module.css"

const TotalCompleteItems = () => {
	const todos = useSelector((state) =>
		state.todos.filter((todo) => todo.completed === true)
	)

	return <p className={classes.TotalCompleteItems}>Завершено задач: {todos.length}</p>;
};

export default TotalCompleteItems;
